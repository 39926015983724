(function () {
    'use strict';

    angular
        .module('lariAidApp')
        .controller('CountryAdminController', CountryAdminController);

    CountryAdminController.$inject = ['$element', 'BaseFactory', 'APP', 'UserService', '$mdMedia', 'deviceDetector', '$sessionStorage', 'tabservice', '$q', '$state', '$scope', '$mdSidenav', 'StorageDelete', '$cookies', 'CommonService', '$mdDialog', '$location', '$timeout', '$stateParams', 'notifications'];
    function CountryAdminController($element, BaseFactory, APP, UserService, $mdMedia, deviceDetector, $sessionStorage, tabservice, $q, $state, $scope, $mdSidenav, StorageDelete, $cookies, CommonService, $mdDialog, $location, $timeout, $stateParams, notifications) {
        var vm = this;
        vm.allCountry = [];
        vm.showCountrydetails = [];
        vm.personSelectedCountry = [];
        vm.selectedAdminList = [];
        vm.savedList = [];
        vm.countryFilter = [];//side filter param
        vm.countrySearchKey = '';//overall SearchKey
        $scope.countryInsideSearch = '';
        vm.regionSearchKey = '';
        vm.allCountries = false;
        vm.deletedItemList = [];
        vm.missingCountryDetails = [];
        vm.allCoutrySelect = [];
        vm.userEndlessScrollRetain = {};
        vm.currentDate = new Date();
        vm.endDate = new Date();
        vm.selectAllCountryStartDate = new Date();
        vm.selectAllCountryStartDate = '';
        vm.selectAllCountryEndDate = new Date();
        vm.selectAllCountryEndDate = null;
        vm.futureState;
        vm.isSaveCountryAdminDetails = true;
        $sessionStorage.isSaveCountryAdminDetails = true;
        vm.isCountryChanged = false;
        vm.remainingCountryList = [];
        vm.isCountryIsSelected = false;
        vm.APP = APP;
        vm.isCountryFieldChanged = false;
        $scope.countrydetailsShowId = [];

        /**
         * Get the country admin details call 
         * @author Ganeshkumar.K
         */

        vm.getAdminDetails = function () {
            vm.getAllCountry();
            setTimeout(function () {
                vm.countryFilterIds = [];
                vm.countryAdminData = [];
                if (vm.countryFilter.length > 0) {
                    vm.isFilterEnable = true;
                    angular.forEach(vm.countryFilter, function (country, index) {
                        vm.countryFilterIds.push(country.id);
                    });
                }
                vm.adminLoader = true;
                vm.initialLoading = true;
                var data = UserService.getAdminDetails(vm.countryFilterIds, vm.countrySearchKey);
                data.then(function (response) {
                    vm.countryAdminData = response;
                    if (vm.savedList.length > 0) {
                        for (var i = 0; i < vm.countryAdminData.length; i++) {
                            for (var j = 0; j < vm.savedList.length; j++) {
                                if (vm.savedList[j].userId === vm.countryAdminData[i].userId) {
                                    vm.countryAdminData.splice(i, 1);
                                    vm.countryAdminData.push(vm.savedList[j]);
                                }
                            }
                        }
                    }
                    angular.forEach(vm.countryAdminData, function (adminData, index) {
                        angular.forEach(adminData.countryListDTO, function (list, listIndex) {
                            angular.forEach(vm.allCountry, function (all, countryIndex) {
                                if (all.id == list.country.id) {
                                    list.country = all;
                                }
                                if (list.country.name == 'No country') {
                                    list.country = {};
                                }
                            });
                        });
                    });

                    angular.copy(vm.allCountry, vm.remainingCountryList);
                    vm.isCountryIsSelected = false;
                    if (vm.countryFilter.length == 0) {
                        vm.filterAllCountry = vm.allCountry;
                    }
                    vm.adminLoader = false;
                },
                    function (error) {
                        vm.adminLoader = false;
                        vm.countryAdminData = [];
                        notifications.showError({
                            message: vm.APP.LIBRARY.COUNTRY_ADMIN_GET_PROBLEM
                        });

                    }
                );
            }, 1000);
        };

        /**
        * Get the all prospect birth country details
        * @returns 
        */

        vm.getAllCountry = function () {
            var data = CommonService.getBirthCountry();
            data.then(function (response) {
                vm.allCountry = response;
                vm.showCountrydetails = vm.allCountry;
            });
        };

        /**
         * Common save call for countryAdmin Details
         */
        vm.saveCountryAdminDetails = function () {
            vm.isSaveCountryAdminDetails = false;
            $scope.noCountry = null;
            if (vm.savedList.length > 0) {
                angular.forEach(vm.savedList, function (list, index) {
                    if (list.countryListDTO.length == 1 && list.countryListDTO[0].country.id == undefined) {
                        $scope.noCountry = list;
                    }
                    angular.forEach(list.countryListDTO, function (countryList, $countryIndex) {
                        if (countryList.startDate != null) {
                            countryList.startDate = new Date(countryList.startDate);
                        }
                        if (countryList.endDate != null) {
                            countryList.endDate = new Date(countryList.endDate);
                        }
                        if (countryList.country.id == undefined) {
                            vm.savedList[$countryIndex].countryListDTO = [];
                        }
                    });
                });
                // vm.adminLoader = true;
                var data = UserService.saveAdminDetails(vm.savedList);
                data.then(function (response) {
                    if (response) {
                        notifications.showSuccess({ message: vm.APP.LIBRARY.COUNTRY_ADMIN_SAVE });
                        vm.savedList = [];
                        vm.selectedAdminList = [];
                        vm.clearSelectedList();
                        // vm.getAdminDetails();
                        $mdDialog.hide();
                        if ($scope.noCountry != null) {
                            vm.addOrRemoveCountry($scope.noCountry, null, true)
                        }
                    }
                }, function (reason) {
                    vm.adminLoader = false;
                    if (reason) {
                        notifications.showError({ message: vm.APP.LIBRARY.COUNTRY_ADMIN_SAVE_PROBLEM });
                    }
                }
                );
                vm.isSaveCountryAdminDetails = true;
                $sessionStorage.isSaveCountryAdminDetails = true;
            }
        };

        /**
         * method for filters selectall countries 
         */

        vm.selectAllCountries = function () {
            vm.countryFilter = [];
            if (!vm.allCountries) {
                angular.forEach(vm.filterAllCountry, function (item) {
                    item.hidden = true;
                });
                $timeout(function () {
                    angular.forEach(vm.filterAllCountry, function (item) {
                        vm.countryFilter.push(item);
                    });
                    vm.regionsLoader = false;
                }, 0);

            } else {
                angular.forEach(vm.filterAllCountry, function (item) {
                    item.hidden = false;
                });
                vm.regionsLoader = false;
            }
        };

        /**
         * show the dialog box when click a edit button in admins tab
         * @param {Event} $event 
         * @param {String} id 
         * @param {*} index 
         */

        vm.showDialog = function ($event, id, index) {
            if (!(angular.isUndefined(index))) {
                vm.dialogIndex = index;
            }
            $mdDialog.show({
                contentElement: '#' + id,
                parent: angular.element(document.body),
                targetEvent: $event,
                clickOutsideToClose: true
            });
        };
        /**
         * Close method for Dialog box
         * @author Ganeshkumar.K
         */

        vm.closeDialog = function () {
            if (vm.missingCountryDetails.length > 0) {
                angular.forEach(vm.missingCountryDetails, function (data, index) {
                    var idx = vm.deletedItemList.indexOf(data);
                    if (idx == -1) {
                        vm.deletedItemList.push(data);
                    }
                });
                if (!vm.isSaveButtonEnable()) {
                    vm.saveCountryAdminDetails();
                }
                vm.missingCountryDetails = [];
                $mdDialog.hide();
            }
            else {
                $mdDialog.hide();
            }
        };


        /**
         * Get the minimum date based on each admin's start date.
         * @param {Date} startDate 
         * @since 25-11-2023
         * @author Ganeshkumar.K
         * 
         */

        vm.getMinDate = function (startDate) {
            vm.minDate = new Date(startDate);
        };

        /**
         * clear the overall selectedlist 
         */

        vm.clearSelectedList = function () {
            if (vm.selectedAdminList.length == 0) {
                vm.allCoutrySelect = [];
                vm.selectAllCountryStartDate = '';
                vm.selectAllCountryEndDate = null;
            }
        };
        /**
         * when user edit the country admin the details will saved in savedList
         * @param {*} countryDetails 
         * 
         */

        vm.changedAdminList = function (countryDetails, individualCountryDetails) {
            if (vm.isCountryChanged) {
                vm.changeStartDateIntoCurrentDate(countryDetails, individualCountryDetails);
            }
            //vm.clearEndDateBasedOnStartDate(countryDetails, individualCountryDetails);
            var idx = vm.savedList.indexOf(countryDetails);
            if (idx > -1) {
                vm.savedList.splice(idx, 1);
                vm.savedList.push(countryDetails);
            }
            else {
                vm.savedList.push(countryDetails);
            }
            if (vm.missingCountryDetails.length > 0) {
                vm.showWarning('warningPopupMsgForActiveInvestigation');
            }
            else if (!vm.isSaveButtonEnable()) {
                vm.saveCountryAdminDetails();
            }
        };

        vm.stateInternalDetails = function () {
            // The manager edit the country admin at time we call the warning popup functionality
            if (!($sessionStorage.isSaveCountryAdminDetails) && $sessionStorage.userManagementActiveState == vm.APP.USER.ADMIN) {
                vm.futureState = vm.APP.USER.INTERNAL;
                vm.showWarning('warningPopupMsg');
            }
            else {
                if ($sessionStorage.userManagementActiveState == vm.APP.USER.EXTERNAL || $sessionStorage.userManagementActiveState == vm.APP.USER.ADMIN) {
                    StorageDelete.deleteUser();
                }
                $sessionStorage.userManagementActiveState = vm.APP.USER.INTERNAL;
                vm.userTabIndex = 1;
                $state.go(vm.APP.USER.INTERNAL);
            }
            $sessionStorage.futureState = vm.APP.USER.INTERNAL;

        };

        vm.stateExternalDetails = function () {
            if (!($sessionStorage.isSaveCountryAdminDetails) && $sessionStorage.userManagementActiveState == vm.APP.USER.ADMIN) {
                vm.futureState = vm.APP.USER.EXTERNAL;
                vm.showWarning('warningPopupMsg');
            }
            else {
                if ($sessionStorage.userManagementActiveState == vm.APP.USER.INTERNAL || $sessionStorage.userManagementActiveState == vm.APP.USER.ADMIN) {
                    StorageDelete.deleteUser();
                }
                $sessionStorage.userManagementActiveState = vm.APP.USER.EXTERNAL;
                vm.userTabIndex = 2;
                $state.go(vm.APP.USER.EXTERNAL);

            }
            $sessionStorage.futureState = vm.APP.USER.EXTERNAL;
        };

        /**
         * save the country admin details when list is changed 
         */

        vm.isChanged = function (countryData, country) {
            if (!vm.initialLoading && vm.isCountryFieldChanged) {
                vm.isCountryChanged = true;
                vm.changedAdminList(countryData, country)
                vm.isCountryFieldChanged = false;
            }
        };
        /**
         * show the warning message to user when move to next tab without saving the country admin details
         * @param {Element} popupId 
         */

        vm.showWarning = function (popupId) {
            var event = this;
            $mdDialog.show({
                contentElement: angular.element('#' + popupId),
                targetEvent: event,
                clickOutsideToClose: false,
                escapeToClose: false
            });
        };

        /**
         * The function call when user select yes in the warning popup
         * 
         */


        vm.changeSection = function () {
            if ($sessionStorage.userManagementActiveState == vm.APP.USER.ADMIN) {
                StorageDelete.deleteUser();
            }
            if ($sessionStorage.futureState != null) {
                vm.futureState = $sessionStorage.futureState;
                $sessionStorage.userManagementActiveState = vm.futureState;
                $state.go(vm.futureState);
                if (vm.futureState == vm.APP.USER.INTERNAL) {
                    vm.userTabIndex = 1;
                }
                else if (vm.futureState == vm.APP.USER.EXTERNAL) {
                    vm.userTabIndex = 2;
                }
                vm.setUserType();
            }
        };

        /**
        * Set the placeholder for country Dropdown
        * @since 06-12-2023	 
        * @param {*} country 
        * @param {*} index 
        * @param {*} type 
        */

        vm.setCountryPlaceholder = function (country, index, type) {
            if (type == 'sideFilter') {
                var element = document.getElementById("sideFilter");
                if (country.length == vm.allCountry.length) {
                    var header = "All Countries Selected";
                    this.setHeaderContent(element, header);
                }
            }
        };
        /**
         * Set the Headercontent based on element
         * @param {HTMLAnchorElement} element 
         * @param {String} header 
         */

        vm.setHeaderContent = function (element, header) {
            if (element != null) {
                var child1 = element.children[0];
                if (child1 != null) {
                    var child2 = child1.children[0];
                    if (child2 != null) {
                        child2.innerHTML = header;
                    }
                }
            }
        }

        /**
         * @since 15-09-2024
         * @author Ganeshkumar.K
         * 
         */
        vm.addOrRemoveCountry = function (countryList, removedCountry, isCountryAdded, parentIndex, index) {
            if (isCountryAdded) {
                var countryModel = {
                    "country": {},
                    "startDate": null,
                    "endDate": null,
                    "deleted": false
                };
                countryList.countryListDTO.push(countryModel);
            }
            else {
                var countryIdx = countryList.countryListDTO.indexOf(removedCountry);
                if (countryIdx > -1) {
                    countryList.countryListDTO.splice(countryIdx, 1);
                }
                var idx = vm.savedList.indexOf(countryList);
                if (idx > -1) {
                    vm.savedList.splice(idx, 1);
                    vm.savedList.push(countryList);
                }
                else {
                    vm.savedList.push(countryList);
                }

                vm.checkInvestigationWarning(parentIndex, index);


                if (vm.missingCountryDetails.length > 0) {
                    vm.showWarning('warningPopupMsgForActiveInvestigation');
                } else if (!vm.isSaveButtonEnable()) {
                    vm.saveCountryAdminDetails();
                }
            }
        }

        /**
         * Clear the filter value 
         */

        vm.clear = function () {
            if (vm.isFilterEnable) {
                vm.countryFilter = [];
                vm.allCountries = false;
                vm.isFilterEnable = false;
                vm.getAdminDetails();
            }
            else if (vm.countryFilter.length > 0) {
                vm.countryFilter = [];
                vm.allCountries = false;
                vm.isFilterEnable = false;
                vm.getAdminDetails();
            }
            vm.regionSearchKey = '';
        };

        vm.getRemainingCountryList = function (country) {
            vm.remainingCountryList = angular.copy(vm.allCountry);
            vm.isCountryIsSelected = true;
            angular.forEach(country.countryListDTO, function (list, listIndex) {
                angular.forEach(vm.remainingCountryList, function (remainingCountryList, countryIndex) {
                    if (remainingCountryList.id == list.country.id) {
                        remainingCountryList.isDisabled = true;
                    }
                });
            });
        }

        vm.resetRemainingList = function () {
            vm.remainingCountryList = angular.copy(vm.allCountry);
            vm.isCountryIsSelected = false;
        }

        /**
        * Individual save call for save the country Admin details
        */

        vm.individualSaveCountryDetails = function () {
            var tempList = [];
            if (vm.dialogIndex != null) {
                tempList.push(vm.countryAdminData[vm.dialogIndex]);
            }
            var idx = vm.savedList.indexOf(vm.countryAdminData[vm.dialogIndex]);
            if (idx > -1) {
                vm.savedList.splice(idx, 1);
            }
            if (tempList.length > 0) {
                angular.forEach(tempList, function (list, index) {
                    if (list.startDate != null) {
                        list.startDate = new Date(list.startDate);
                    }
                    if (list.endDate != null) {
                        list.endDate = new Date(list.endDate);
                    }

                    if (list.country.length == vm.allCountry.length) {
                        list.selectedAll = true;
                        list.country = [];
                    }
                    else {
                        list.selectedAll = false;
                    }
                });
                vm.adminLoader = true;
                var data = UserService.saveAdminDetails(tempList);
                data.then(function (response) {
                    if (response) {
                        notifications.showSuccess({ message: vm.APP.LIBRARY.COUNTRY_ADMIN_SAVE });
                        vm.isSaveCountryAdminDetails = (vm.savedList.length == 0 ? true : false);
                        $sessionStorage.isSaveCountryAdminDetails = vm.isSaveCountryAdminDetails;
                        $mdDialog.hide();
                        vm.selectedAdminList = [];
                        vm.clearSelectedList();
                        vm.getAdminDetails();

                    }
                }, function (reason) {
                    if (reason) {
                        notifications.showError({ message: vm.APP.LIBRARY.COUNTRY_ADMIN_SAVE_PROBLEM });
                        vm.adminLoader = false;
                        $mdDialog.hide();
                        vm.isSaveCountryAdminDetails = (vm.savedList.length == 0 ? true : false);
                        $sessionStorage.isSaveCountryAdminDetails = vm.isSaveCountryAdminDetails;
                    }
                }
                );
            }

        };

        /**
        * 
        * @param {*} index 
        * show the warning popup when country admin have active investigation
        */

        vm.checkInvestigationWarning = function (listIndex, countryIndex) {
            vm.missingCountryDetails = [];
            if (countryIndex > -1) {
                var tempSelectedCountryDetails = [];
                angular.forEach(vm.countryAdminData[listIndex].countryListDTO, function (country, idx) {
                    tempSelectedCountryDetails.push(country.country.id);
                });
                angular.forEach(vm.countryAdminData[listIndex].assignCountry, function (assignCountry, cidx) {
                    var idx = tempSelectedCountryDetails.indexOf(assignCountry.country.id);
                    if (idx == -1) {
                        vm.missingCountryDetails.push(assignCountry);
                    }
                });
            }
            if (vm.deletedItemList.length > 0) {
                angular.forEach(vm.missingCountryDetails, function (data, index) {
                    var idx = vm.deletedItemList.indexOf(data);
                    if (idx > -1) {
                        vm.missingCountryDetails.splice(idx, 1);
                    }
                });
            }
            if (vm.missingCountryDetails.length > 0) {
                vm.showWarning('warningPopupMsgForActiveInvestigation');
            }
        }


        /**
        * check the MandatoryField for individuals 
        * @param {Object} individualCountryAdminData 
        * @returns {Boolean}
        */
        vm.checkMandatoryFieldForIndividualCountryAdmin = function (individualCountryAdminData) {
            if (angular.isDefined(individualCountryAdminData)) {
                if (vm.savedList.indexOf(individualCountryAdminData) == -1) {
                    return true;
                }
                else {
                    return (individualCountryAdminData.country.length > 0 && angular.isUndefined(individualCountryAdminData.startDate) ? true : false);
                }
            }
        };
        /**
        * Clear the endDate based on a start date
        * @param {Object} countryData 
        */

        vm.clearEndDateBasedOnStartDate = function (countryData, individualCountryDetails) {
            var stateDateTemp = new Date(individualCountryDetails.startDate);
            var endDateTemp = new Date(individualCountryDetails.endDate);
            if (angular.isDefined(individualCountryDetails.country) && stateDateTemp > endDateTemp) {
                individualCountryDetails.endDate = null;
            }
        };

        vm.isSaveButtonEnable = function () {
            var tmp = false;
            angular.forEach(vm.savedList, function (list, index) {
                angular.forEach(list.countryListDTO, function (countryList, $countryIndex) {
                    if (!angular.isObject(countryList.country) || (countryList.country != null && angular.isUndefined(countryList.startDate))) {
                        tmp = true;
                    }
                });
            });
            if ($sessionStorage.isSaveCountryAdminDetails) {
                delete $sessionStorage.isSaveCountryAdminDetails
                $sessionStorage.isSaveCountryAdminDetails = false;
            }
            vm.isSaveCountryAdminDetails = tmp;

            return vm.isSaveCountryAdminDetails;
        }



        vm.checkMandatoryField = function (countryData) {

        }
        /**
        * Change the start date into a current date
        */
        vm.changeStartDateIntoCurrentDate = function (countryDetails, individualCountryDetails) {
            var currentDate = new Date();
            individualCountryDetails.startDate = currentDate;
            vm.isCountryChanged = false;
        };

        vm.isDisabled = function (countryObj, countryList) {
            return countryObj.country.name == null || countryObj.startDate == null || vm.allCountry.length <= countryList.countryListDTO.length;
        };


        vm.countryAdminDetailsShow = function (show, icon, country) {
            if (show == "countryDetails") {
                if (icon) {
                    country.detailsShow = true;
                    $scope.countrydetailsShowId.push(country.userId);
                } else {
                    country.detailsShow = false;
                    let index = $scope.countrydetailsShowId.indexOf(country.userId);
                    $scope.countrydetailsShowId.splice(index, 1);
                }
            }
        };

        vm.addIndex = function (show, index, country) {
            show = show + 1;
        };

        vm.isChecked = function (selectedList, overallData, type) {
            if (selectedList != null && overallData != null) {
              if (type == 'country') {
                  selectedList.selectedAll = (selectedList.country.length === overallData.length);
                  return selectedList.length === overallData.length;
              } else if (type == 'overallSelect') {
                  vm.selectedAll = (selectedList.length === overallData.length);
                  return selectedList.length === overallData.length;
              } else {
                  return selectedList.length === overallData.length;
              }
            }
        };

    };
})();